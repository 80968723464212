<template>
  <section class="containerMyRequest">
    <section class="containerMyRequest__info">
      <backButton altRedirection="resultsSearch"></backButton>
      <div class="containerMyRequest__info--header">
        <span class="title">My Requests</span>
        <section class="newRequestContainer">
          <button class="newRequestContainer__button" @click="toggleDropdown">
            <span class="grey-icon-close"></span>
            <span class="newRequestContainer__button--label">{{`NEW${!store.state.isMobileResolution ? ' REQUEST' : ''}`}}</span>
            <span class="grey-icon-arrow-down"></span>
          </button>
          <transition enter-active-class="dropdownAnimationIn" leave-active-class="dropdownAnimationOut">
            <ul class="newRequestContainer__options" v-if="showNewRequestDropdown" v-click-away="toggleDropdown">
              <li class="newRequestContainer__options--option" v-for="form in forms" :key="form.label">
                <a class="link" target="_blank" :href="form.link">{{form.label}}</a>
              </li>
            </ul>
          </transition>
        </section>
      </div>
      <section v-if="requestsLoaded && !requests.length" class="containerMyRequest_info--noRequests">
        <img class="noRequestsImage" src="@/assets/Request-01.svg" />
        <span class="label">No requests have been created yet!</span>
      </section>
      <section class="containerMyRequest_info--list" v-else-if="requestsLoaded">
        <ul class="myRequestList">
          <li class="myRequestList__card" v-for="(request, index) in requests" :key="index">
            <span :class="isNewClientBrandRequest(request.type) ? 'grey-icon-new-client' : 'grey-icon-external-client'"></span>
            <span class="myRequestList__card--title" :title="request.title">{{request.title}}</span>
            <div class="myRequestList__card--primaryData">
              <div class="brand" v-if="request.brand">
                <span class="label">Brand: </span>
                <span class="info" :title="request.brand">{{request.brand}}</span>
              </div>
              <div class="type">
                <span class="label">Type: </span>
                <span class="info" :title="forms[request.type].label">{{forms[request.type].label}}</span>
              </div>
            </div>
            <div class="myRequestList__card--dataField status" v-if="request.status">
              <span class="label">{{setDataLabel('Status')}}</span>
              <span class="info">{{request.status}}</span>
            </div>
            <div class="myRequestList__card--dataField agency" v-if="request.agency">
              <span class="label">{{setDataLabel('Agency')}}</span>
              <span class="info" :title="request.agency">{{request.agency}}</span>
            </div>
            <div class="myRequestList__card--dataField region" v-if="request.region">
              <span class="label">{{setDataLabel('Region')}}</span>
              <span class="info" :title="request.region">{{request.region}}</span>
            </div>
            <div class="myRequestList__card--dataField email" v-if="request.email">
              <span class="label">{{setDataLabel('Email')}}</span>
              <span class="info" :title="request.email">{{request.email}}</span>
            </div>
            <div class="myRequestList__card--dataField date">
              <span class="label">{{setDataLabel('Request Date')}}</span>
              <span class="info" :title="store.getters.formatDate(request.requestDate)">{{store.getters.formatDate(request.requestDate)}}</span>
            </div>
            <span class="grey-icon-preview" @click="togglePreviewModal(true, request)"></span>
          </li>
        </ul>
      </section>
      <skeletonMyRequest v-else></skeletonMyRequest>
      <genericModalLogic
        theme="gray"
        v-if="showRequestPreview"
        class="requestPreviewModal"
        :title="requestToOpenPreview.title"
        @closeModal="togglePreviewModal(false)">
        <requestPreview
          :requestId="requestToOpenPreview.id"
          :requestType="requestToOpenPreview.type">
        </requestPreview>
      </genericModalLogic>
    </section>
    <paginator v-if="requestsLoaded"
      :totalItems="store.state.myRequestPagination.items.length"
      :maxPages="store.state.myRequestPagination.maxPages"
      :pagination="store.state.myRequestPagination"
      @goToPage="goToPage">
    </paginator>
  </section>
</template>

<script>

import { onMounted, ref, computed, reactive, nextTick } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import backButton from '@/components/backButton/backButton'
import skeletonMyRequest from '@/components/skeletons/skeletonMyRequest/skeletonMyRequest'
import genericModalLogic from '@/components/genericModalLogic/genericModalLogic'
import requestPreview from '@/components/requestPreview/requestPreview'
import forms from './formsLinks.json'
import paginator from '@/components/paginator/paginator'

export default {
  name: 'myRequest',
  components: {
    backButton,
    skeletonMyRequest,
    genericModalLogic,
    requestPreview,
    paginator
  },

  setup (props) {
    const store = useStore()
    const route = useRoute()
    const showNewRequestDropdown = ref(false)
    const requestsLoaded = ref(false)
    const requests = ref([])
    const showRequestPreview = ref(false)
    const requestToOpenPreview = reactive({
      id: '',
      type: '',
      title: ''
    })

    /**
     * @description Checks the type of a request
     * @param {String} type the type field of request
     */
    const isNewClientBrandRequest = computed(() => (type) => {
      return type === 'clientBrand'
    })

    /**
     * @description Adds colon to label if is mobile resolution
     * @param {String} label the label to add the colon
     */
    const setDataLabel = computed(() => (label) => {
      return label + (store.state.isMobileResolution ? ':' : '')
    })

    onMounted(() => {
      store.commit({
        type: 'SET_MY_REQUESTS_PAGINATION',
        size: route.query.t,
        page: route.query.k
      })
      getMyRequests()
    })

    /**
     * @description Show or hide dropdown
     */
    function toggleDropdown () {
      showNewRequestDropdown.value = !showNewRequestDropdown.value
    }

    /**
     * @description Call service to get user's requests data
     */
    function getMyRequests () {
      requestsLoaded.value = false
      if (!store.state.myRequestPagination.items.length) {
        store.dispatch({
          type: 'getMyRequestsData'
        }).then(() => {
          setItemsPaginator()
        })
      } else {
        setItemsPaginator()
      }
    }

    /**
     * @description Set items of the current page
     */
    function setItemsPaginator () {
      const page = store.state.myRequestPagination.page
      const size = store.state.myRequestPagination.size
      requests.value = store.state.myRequestPagination.items.slice((page - 1) * size, page * size)
      nextTick(() => {
        requestsLoaded.value = true
      })
    }

    /**
     * @description Toggles preview modal visibility and saves data of card selected
     * @param {Boolean} flag to show or hide modal
     * @param {Object} request data of card selected to open preview
     */
    function togglePreviewModal (flag, request) {
      showRequestPreview.value = flag
      if (request) {
        requestToOpenPreview.id = request.id
        requestToOpenPreview.type = request.type
        requestToOpenPreview.title = request.title
      }
    }

    /**
     * @description Execute the action required when a new page is selected
     * @param pageNumber Number of the page selected
     */
    function goToPage (pageNumber) {
      store.commit({
        type: 'SET_MY_REQUESTS_PAGINATION',
        size: store.state.myRequestPagination.size,
        page: pageNumber
      })
      store.commit({
        type: 'UPDATE_MY_REQUESTS_URL'
      })
      getMyRequests()
    }

    return {
      store,
      forms,
      showNewRequestDropdown,
      toggleDropdown,
      requestsLoaded,
      requests,
      isNewClientBrandRequest,
      showRequestPreview,
      togglePreviewModal,
      requestToOpenPreview,
      goToPage,
      setDataLabel
    }
  }
}
</script>
