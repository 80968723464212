<template>
  <div class="containerSkeletonMyRequest">
    <div class="containerSkeletonMyRequest__card" v-for="item in items" :key="item">
      <div class="containerSkeletonMyRequest__card--icon"></div>
      <div class="containerSkeletonMyRequest__card--title"></div>
      <div class="containerSkeletonMyRequest__card--primaryData" v-if="!store.state.isMobileResolution">
        <div class="brand"></div>
        <div class="type"></div>
      </div>
      <div class="containerSkeletonMyRequest__card--status" v-if="!store.state.isMobileResolution">
        <div class="dataLine"></div>
        <div class="dataLine"></div>
      </div>
      <div class="containerSkeletonMyRequest__card--data" v-for="dataItem in dataItems" :key="dataItem">
        <div class="dataLine"></div>
        <div class="dataLine"></div>
        <div class="dataLine" v-if="store.state.isMobileResolution"></div>
      </div>
      <div class="containerSkeletonMyRequest__card--preview"></div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'skeletonMyRequest',
  setup (props) {
    const store = useStore()
    const items = ref(12)

    /**
     * @description Get number of data fields according screen resolution
     */
    const dataItems = computed(() => {
      return store.state.isMobileResolution ? 1 : 2
    })

    return {
      store,
      items,
      dataItems
    }
  }
}
</script>
