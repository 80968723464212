<template>
  <section class="containerRequestPreview" v-if="dataLoaded">
    <header class="containerRequestPreview__header">
      <div class="containerRequestPreview__header--icon">
        <span :class="isNewClientBrandRequest ? 'grey-icon-new-client' : 'grey-icon-external-client'"></span>
      </div>
      <div class="containerRequestPreview__header--data" :class="{'newClient': isNewClientBrandRequest}">
        <p class="data data--name">{{store.getters.formatAuthorName(requestData.name)}}</p>
        <p class="data data--type">
          <span class="data__label">Type: </span>
          {{isNewClientBrandRequest ? 'New Client/Brand Request' : 'Third Party Access Request'}}
        </p>
        <p class="data data--brand" :title="requestData.brand">
          <span class="data__label">Brand: </span>
          {{dataToShow(requestData.brand)}}
        </p>
        <p class="data data--email" :title="requestData.email">
          <span class="data__label">Email: </span>
          {{dataToShow(requestData.email)}}
        </p>
        <p class="data data--status">
          <span class="data__label">Status: </span>
          <span class="data__statusTag" :class="{'empty': !requestData.status}">{{dataToShow(requestData.status)}}</span>
        </p>
        <p class="data data--discipline" v-if="requestData.discipline" :title="requestData.discipline">
          <span class="data__label">Discipline: </span>
          {{dataToShow(requestData.discipline)}}
        </p>
      </div>
    </header>
    <section class="containerRequestPreview__body">
      <div class="containerRequestPreview__body--newClient" v-if="isNewClientBrandRequest">
        <div class="newClientRequest">
          <p class="newClientRequest__data">
            <span class="newClientRequest__data--label">Is this a new client or new brand or both? </span>
            {{dataToShow(requestData.isNewClientOrBrand)}}
          </p>
          <p class="newClientRequest__data">
            <span class="newClientRequest__data--label">Client: </span>
            {{dataToShow(requestData.title)}}
          </p>
          <p class="newClientRequest__data">
            <span class="newClientRequest__data--label">Full name of the client: </span>
            {{dataToShow(requestData.clientFullName)}}
          </p>
          <p class="newClientRequest__data">
            <span class="newClientRequest__data--label">Is this a global client or a client served by 3 or more grey agencies? </span>
            {{dataToShow(requestData.isGlobalClient)}}
          </p>
          <p class="newClientRequest__notes">
            <span class="newClientRequest__notes--label">Request Notes: </span>
            {{dataToShow(requestData.notes, 'notes')}}
          </p>
        </div>
      </div>
      <div class="containerRequestPreview__body--externalClient" v-else>
        <div class="externalClientRequest">
          <div class="externalClientRequest__data">
            <p class="externalClientRequest__data--title">ACCESS</p>
            <p class="externalClientRequest__data--field">
              <span class="externalRequestlabel">Applications: </span>
              {{dataToShow(requestData.applications)}}
            </p>
            <p class="externalClientRequest__data--field">
              <span class="externalRequestlabel">I confirm that the above selected access is appropriate: </span>
              {{dataToShow(requestData.appropriateAccessConfirmation)}}
            </p>
          </div>
          <div class="externalClientRequest__data">
            <p class="externalClientRequest__data--title">AGENCY INFO</p>
            <p class="externalClientRequest__data--field">
              <span class="externalRequestlabel">Operating company: </span>
              {{dataToShow(requestData.operatingCompany)}}
            </p>
            <p class="externalClientRequest__data--field">
              <span class="externalRequestlabel">Agency: </span>
              {{dataToShow(requestData.agency)}}
            </p>
            <p class="externalClientRequest__data--field">
              <span class="externalRequestlabel">Region: </span>
              {{dataToShow(requestData.region)}}
            </p>
            <p class="externalClientRequest__data--field">
              <span class="externalRequestlabel">Cities: </span>
              {{dataToShow(requestData.cities)}}
            </p>
          </div>
        </div>
      </div>
    </section>
  </section>
  <section class="containerRequestPreviewLoading" v-else>
    <spinner></spinner>
  </section>
</template>

<script>
import { onMounted, ref, computed, reactive } from 'vue'
import { useStore } from 'vuex'
import spinner from '@/components/spinner/spinner'

export default {
  name: 'requestPreview',
  components: {
    spinner
  },
  props: {
    requestId: {
      type: String,
      required: true
    },
    requestType: {
      type: String,
      required: true
    }
  },
  setup (props, { emit }) {
    const store = useStore()
    const requestData = ref(null)
    const dataLoaded = ref(false)
    const emptyMessages = reactive({
      generalField: 'Not specified',
      notes: 'No comments for this request.'
    })

    /**
     * @description Checks the type of request
     */
    const isNewClientBrandRequest = computed(() => {
      return props.requestType === 'clientBrand'
    })

    /**
     * @description Checks if data exists and sets its value whether is empty or not
     * @param {String} data the data field to check and set
     * @param {String} fieldType the field type to set data of
     */
    const dataToShow = computed(() => (data, fieldType = 'generalField') => {
      return !data ? emptyMessages[fieldType] : data
    })

    onMounted(() => {
      getPreviewData()
    })

    /**
     * @description Call service to get request details
     */
    function getPreviewData () {
      dataLoaded.value = false
      store.dispatch({
        type: 'getRequestDetails',
        requestId: props.requestId,
        requestType: props.requestType
      }).then((response) => {
        dataLoaded.value = true
        requestData.value = response
      })
    }

    return {
      store,
      requestData,
      dataLoaded,
      isNewClientBrandRequest,
      dataToShow
    }
  }
}
</script>
